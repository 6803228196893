.poster-content{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 48.2%;
  margin-top: 52.5%;

  .poster-content-stars{
    width: 50%;
  }

  .poster-content-PP{
    width: 85%
  }
}

  #postercontent-personal-text, #poster-content-info{
    padding: 0 20px;
  }

  #poster-content-artists{
    
    width: 100%;

    .artistName{
      font-family: 'recoletamedium';
      margin: 0;
      text-align: center;
    }
  }

  #poster-content-personal-text{
    // margin-top: 60%;
    .poster-content-topTitle{
      margin: 0;
      text-align: center;
    }
    &>p {
      margin: 0;
      margin-top: -12px;
      font-size: 1.5em;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .poster-content-static{
    margin: 0;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    .poster-content-topTitle{
      font-size: 4.3vh;
    }

    .artistName{
      font-size: 2.8vh;
    }
  }


  @media screen and (max-width: 768px) {
    .poster-content-topTitle{
      font-size: 4.3vh;
    }

    .artistName{
      font-size: 2.1vh;
    }

    #poster-content-personal-text{
      &>p {
        font-size: 1.3em;
      }
    }
  }
